.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 20px); /* Adjusted height */
  border: 1px solid #f0f0f0;
  margin: 10px;
  padding-left: 10px;
  padding-right: 10px;
  pading-bottom: 10px;
  border-radius: 5px;
}

.chat-header {
  text-align: center;
  justify-content: center;

}

.messages {
  overflow-y: auto;
  flex-grow: 1;
}

.message-card {
  border: none;
}

.message-card:hover {
  filter: brightness(90%);
}

.chat-header {
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
}